<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte Detallado de Venta</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row  class="row justify-content-md-center">

                <b-col sm="12" md="3">
                  <b-form-group label="Establecimiento">
                    <b-form-select v-model="report.id_establishment" :options="establishment"></b-form-select>
                  </b-form-group>
                </b-col>

                 <b-col sm="12" md="2">
                  <b-form-group label="Desde :">
                    <b-form-input class="text-center" :max="report.to" type="date"  ref="to" v-model="report.from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input class="text-center" :min="report.from" type="date"  ref="from" v-model="report.to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="5">
                  <b-form-group>
                    <label>Producto: </label>
                    <b-form-input class="text-left" :min="report.product" type="text" v-model="report.product"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

            </b-row>
          </b-form>


            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">REPORTE PRODUCTOS VENDIDOS ( {{report.from}} -  {{report.to}}) </th>
                  </tr>
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Código</th>
                    <th class="text-center">Producto</th>
                    <th class="text-center">Categoria</th>
                    <th class="text-center">Marca</th>
                    <th class="text-center">Stock Desde</th>
                    <th class="text-center">Stock Hasta</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{it + 1}}</td>
                    <td class="text-left">{{item.code}}</td>
                    <td class="text-left">{{item.name+ " - "+item.presentation}}</td>
                    <td class="text-center">{{item.category_name}}</td>
                    <td class="text-center">{{item.brand_name}}</td>
                    <td class="text-right">{{item.stock_from}}</td>
                    <td class="text-right">{{item.stock_to}}</td>
                  </tr>
                </tbody>
               
              </table>
              <b-row class="mt-4">
                <b-col md="8">
                  <b-pagination
                    v-model="report.currentPage"
                    v-on:input="Report"
                    :total-rows="report.rows"
                    :per-page="report.perPage"
                    align="center"
                  ></b-pagination>
                </b-col>
                <b-col md="4 text-center">
                  <p>Pagina Actual: {{ report.currentPage }}</p>
                </b-col>
              </b-row>
            </div>

  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportProductSold',
      role:1,
      data_table: [],

      report:{
        perPage: 15,
        currentPage: 1,
        rows: 0,
        id_establishment: 'all',
        from:moment(new Date()).local().format("YYYY-MM-DD"),
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        product:'',
      },
      establishment:[],
      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListEstablishment();
  },
  methods: {
    ListEstablishment,
    Validate,
    Report,
    ExportExcel,
    SearchProduct,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function ExportExcel() {  
  let me = this;
  me.report.id_client = me.client == null ? 'all':me.client.id;
  me.report.id_product = me.product == null ? 'all':me.product.id;
  let url = me.url_base + "excel-report-sales-detail/"+me.report.id_establishment+"/"+me.report.type_invoice+"/"+me.report.way_to_pay+"/"+
  me.report.order_by+"/"+me.report.method+"/"+me.report.month+"/"+me.report.year+"/"+me.report.from+"/"+me.report.to+"/"+me.report.coin+"/"+me.report.id_client+"/"+me.report.id_product;
  window.open(url,'_blank');
}

function ListEstablishment() {

  let me = this;
  let url = this.url_base + "active-establishments";
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.establishment = [{value:'all',text:'Seleccione un establecimiento'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.establishment.push({value: element.id_establishment, text: element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A Ocurrido un error', timer: 2000,})
      }
    })
}

function SearchProduct(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-products-select";
    let data = {
      search:search
    }
    if (search !== "") {
      loading(true);
      axios({
        method: "POST",
        data:data,
        url: url,
      }).then(function (response) {
            me.products = response.data.result;
            loading(false);
      })
    }
    
}

function Validate() {
  
  // this.errors.id_establishment = this.kardex.id_establishment.length == 0 ? true : false;
  // if (this.errors.id_establishment == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  this.Report(this);
}


function Report(me) {
  let data = me.report;
  let url = this.url_base + "report/products-sold";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.report.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
}





</script>
